/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import {Link} from 'react-router-dom'
// import './Sidebar.css';
import '../css/sidebar.css';

const styles = {
  bmItemList: {
      color: '#b8b7ad',
      padding: '0.8em'
  },
  bmItem: {
      color: '#d1d1d1',
      textDecoration: 'none',
      marginBottom: '10px',
      transition: 'color 0.2s',
      '&:hover': {
          color: '#eb4034'
      }
  },
};

const itemStyle = {
  color: '#d1d1d1',
  textDecoration: 'none',
  marginBottom: '10px',
  transition: 'color 0.2s',
};

const itemHoverStyle = {
  color: '#eb4034',
};

export default props => {
  return (
    <Menu right={true} styles={styles}>
        {/* <Link to='/' className='btn'>Home</Link> */}
        {/* <Link to='/aboutme' className='btn'>About Me</Link> */}
        {/* <Link to='/experience' className='btn'>Experience</Link> */}
        {/* <Link to='/projects' className='btn'>Projects</Link>
        <Link to='/contact' className='btn'>Contact Me</Link>
        <a href="https://tropical-roadrunner-d78.notion.site/Sarina-s-Corner-a44daa48a7e64578986486f859f767af" className='btn'>Blog</a> */}
        {/* <Link to='/sarinagpt' className='btn'>SarinaGPT</Link> */}
        <a
            className="menu-item"
            href="/"
            style={itemStyle}
            onMouseEnter={e => e.currentTarget.style.color = itemHoverStyle.color}
            onMouseLeave={e => e.currentTarget.style.color = itemStyle.color}
        >
            Home
        </a>
        <a
            className="menu-item"
            href="/projects"
            style={itemStyle}
            onMouseEnter={e => e.currentTarget.style.color = itemHoverStyle.color}
            onMouseLeave={e => e.currentTarget.style.color = itemStyle.color}
        >
            About
        </a>
        <a
            className="menu-item"
            href="/contact"
            style={itemStyle}
            onMouseEnter={e => e.currentTarget.style.color = itemHoverStyle.color}
            onMouseLeave={e => e.currentTarget.style.color = itemStyle.color}
        >
            Contact
        </a>
        <a
            className="menu-item"
            href="https://tropical-roadrunner-d78.notion.site/Sarina-s-Corner-a44daa48a7e64578986486f859f767af"
            style={itemStyle}
            onMouseEnter={e => e.currentTarget.style.color = itemHoverStyle.color}
            onMouseLeave={e => e.currentTarget.style.color = itemStyle.color}
        >
            Blog
        </a>
    </Menu>
  );
};