import TopBarDesktop from './TopBarDesktop'
import TopBarMobile from './TopBarMobile'
import React from 'react';


function TopBar({
  theme, setTheme
}) {

  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 900;
  React.useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    return () => window.removeEventListener("resize", window.handleWindowResize);
  }, []);
  return width < breakpoint ? <TopBarMobile /> : <TopBarDesktop theme={theme} setTheme={setTheme}/>;
  
}

export default TopBar;